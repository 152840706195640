import * as React from 'react';
import data from '../data/data';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

export default function ImageGrid({ filter }) {
  const isMobile = useMediaQuery('(max-width:743px)');

  const filteredData =
    filter === 'all' ? data : data.filter((item) => item.category === filter);

  const getGridTemplateColumns = () => {
    return window.innerWidth < 460
      ? 'repeat(auto-fill, minmax(250px, 2fr))'
      : 'repeat(auto-fill, minmax(300px, 2fr))';
  };

  const [gridTemplateColumns, setGridTemplateColumns] = React.useState(
    getGridTemplateColumns()
  );

  React.useEffect(() => {
    const handleResize = () => {
      setGridTemplateColumns(getGridTemplateColumns());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: gridTemplateColumns,
        gap: '3px',
      }}
    >
      {filteredData.map((item) => (
        <div
          key={item.id}
          className="image-container" // Ensure this class is applied
          style={{ marginBottom: isMobile ? '5px' : '0px' }}
        >
          <Link to={`/details/${item.title}/${item.id}`}>
            {/* Image */}
            <img
              src={item.mainImage}
              alt={item.title}
              className="image" // Use a class for the image
            />

            {/* Overlay */}
            {!isMobile ? (
              <div className="overlay">{item.title}</div>
            ) : (
              <div className="overlayMobile">
                <div style={{ marginBottom: '5px', marginLeft: '15px' }}>
                  {item.title}
                </div>
              </div>
            )}
            <div className="overlay">{item.title}</div>
          </Link>
        </div>
      ))}
    </div>
  );
}

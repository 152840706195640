import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import CloseIcon from '@mui/icons-material/Close';
import RequestPopup from './RequestPopup';
import { useInView } from 'react-intersection-observer';

const Contact = () => {
  const [requestpopUp, setRequestpopUp] = useState(false);

  const animateText = (text, animate) => {
    return text.split('').map((letter, index) => (
      <span
        key={index}
        className={`animated-letter ${animate ? 'animate' : ''}`}
        style={{ animationDelay: `${index * 0.2}s` }}
      >
        {letter === ' ' ? '\u00A0' : letter}
      </span>
    ));
  };

  // const [aboutRef, aboutInView] = useInView({
  //   triggerOnce: false,
  //   threshold: 0.7, // Trigger when 70% of the section is visible
  // });

  // const [titleRef, titleInView] = useInView({
  //   triggerOnce: false,
  //   threshold: 0.85, // Trigger when 80% of the section is visible
  // });

  // const [paragraphsRef, paragraphsInView] = useInView({
  //   triggerOnce: false,
  //   threshold: 1, // Trigger when 90% of the section is visible
  // });

  // useEffect(() => {
  //   const letters = document.querySelectorAll('.animated-letter');
  //   if (aboutInView) {
  //     letters.forEach((letter) => {
  //       letter.classList.add('animate');
  //     });
  //   } else {
  //     letters.forEach((letter) => {
  //       letter.classList.remove('animate'); // Reset animation when out of view
  //     });
  //   }
  // }, [aboutInView]);

  // useEffect(() => {
  //   const titleLetters = document.querySelectorAll('.title-letter');
  //   if (titleInView) {
  //     titleLetters.forEach((letter) => {
  //       letter.classList.add('animate');
  //     });
  //   } else {
  //     titleLetters.forEach((letter) => {
  //       letter.classList.remove('animate'); // Reset animation when out of view
  //     });
  //   }
  // }, [titleInView]);

  // useEffect(() => {
  //   const paragraphElements = document.querySelectorAll('.paragraph-letter');
  //   if (paragraphsInView) {
  //     paragraphElements.forEach((letter) => {
  //       letter.classList.add('animate');
  //     });
  //   } else {
  //     paragraphElements.forEach((letter) => {
  //       letter.classList.remove('animate'); // Reset animation when out of view
  //     });
  //   }
  // }, [paragraphsInView]);

  const handleOpenRequest = () => {
    setRequestpopUp(true);
  };

  const handleCloseRequest = () => {
    setRequestpopUp(false);
    window.location.reload();
  };

  return (
    <div
      className="brightSection"
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        className="wordContact"
        style={{
          position: 'relative',
          color: '#f2f2f2',
          paddingLeft: '25px',
          fontSize: '14vw',
          letterSpacing: '3vw',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          fontWeight: '600',
          marginTop: '-400px',
        }}
      >
        CONTACT
      </div>

      <div
        className="contactHolder"
        style={{
          position: 'absolute',
          width: '80%',
          right: '0',
          left: '0',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          gap: '10%',
        }}
      >
        <div
          className="titleHolder"
          style={{
            fontSize: '36px',
            fontWeight: '600',
            color: '#000',
            width: '50%',
            marginBottom: '60px',
            lineHeight: '40px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          Connect and Create
          <div
            className="text"
            style={{
              marginTop: '40px',
              fontSize: '17px',
              lineHeight: '25px',
            }}
          >
            <div className="text">
              I would love to hear from you! Whether you have a question, need
              assistance, or want to discuss a potential project, please feel
              free to reach out. I believe in the power of open communication
              and am here to help turn your design dreams into reality.
            </div>
          </div>
        </div>
        <div className="holder" style={{ width: '50%', marginLeft: '30px' }}>
          <div className="text" style={{ marginTop: '15px' }}>
            General Inquiries and Collaborations:
          </div>
          <div
            className="email"
            style={{
              color: '#000',
              fontWeight: '500',
              marginTop: '5px',
              marginBottom: '30px',
            }}
          >
            nidal.wdargham@gmail.com
          </div>

          <div
            className="text"
            style={{
              marginBottom: '35px',
            }}
          >
            Our approach is built on a deep understanding of each space.
          </div>

          <div>
            <button onClick={handleOpenRequest} className="requestButton">
              Send Request{' '}
            </button>
          </div>

          {/* WhatsApp and Instagram Section */}
          <div
            style={{
              marginTop: '40px',
            }}
          >
            <div
              className="whatsapp"
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '30px',
              }}
            >
              <div className="text" style={{ marginBottom: '10px' }}>
                Message me on WhatsApp for quick inquiries:
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <a
                  href="https://wa.me/96171383212"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                  }}
                >
                  <WhatsAppIcon
                    style={{
                      fontSize: '25px',
                      marginRight: '10px',
                      color: '#25D366',
                    }}
                  />
                  <div style={{ fontSize: '16px', color: '#000' }}>
                    +961 71 833 212
                  </div>
                </a>
              </div>
            </div>

            <div
              className="instagram"
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '30px',
              }}
            >
              <div className="text" style={{ marginBottom: '10px' }}>
                Follow me on Instagram for more updates:
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <a
                  href="https://www.instagram.com/nidalbdinteriors/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                  }}
                >
                  <InstagramIcon
                    style={{
                      fontSize: '25px',
                      marginRight: '10px',
                      color: '#E1306C',
                    }}
                  />
                  <div style={{ fontSize: '16px', color: '#000' }}>
                    @ nidalbdinteriors
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RequestPopup isOpen={requestpopUp} onClose={handleCloseRequest} />
    </div>
  );
};

export default Contact;

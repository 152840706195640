const images = [
  {
    imgURL: 'projects/Project1-1.jpg',
    imgAlt: 'The Elegant Salon: A Touch of Luxury',
    title: 'The Elegant Salon',
    link: '/details/The%20Elegant%20Salon:%20A%20Touch%20of%20Luxury/1',
    count: '01',
  },
  {
    imgURL: 'projects/Project4-1.jpg',
    imgAlt: 'Whispers of Nature',
    title: 'Whispers of Nature',
    link: '/details/Whispers%20of%20Nature/4',
    count: '02',
  },
  {
    imgURL: 'projects/Project10-1.jpg',
    imgAlt: 'Fresh Perspectives: An Open Villa Living and Dining Oasis',
    title: 'Fresh Perspectives',
    link: '/details/Fresh%20Perspectives:%20An%20Open%20Villa%20Living%20and%20Dining%20Oasis/10',
    count: '03',
  },
];

export default images;

import { React, useState } from 'react';
import ProjectsFilter from '../components/ProjectFilter';
import ImageGrid from '../components/ImageGrid';

const Projects = () => {
  const [filter, setFilter] = useState('all');

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };
  return (
    <div>
      <div className="brightSection">
        <div
          style={{
            position: 'relative',
            color: '#f2f2f2',
            paddingLeft: '25px',
            fontSize: '14vw',
            letterSpacing: '3vw',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            fontWeight: '500',
            paddingTop: '100px',
          }}
        >
          PROJECTS
        </div>
        <div className="holderGridFilter" style={{ margin: '20px 70px' }}>
          <div className="projectsFilter">
            <ProjectsFilter onFilterChange={handleFilterChange} />
          </div>
          <div className="imageGridContainer">
            <ImageGrid filter={filter} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;

import {
  AppBar,
  Button,
  IconButton,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { replace, useNavigate } from 'react-router-dom';
import RequestPopup from './RequestPopup';

const Navbar = () => {
  //#region hooks and queries
  // State hooks
  const [scrollPosition, setScrollPosition] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [requestpopUp, setRequestpopUp] = useState(false);

  // Media queries
  const isMobile = useMediaQuery('(max-width:800px)');
  const hideRequest = useMediaQuery('(max-width:640px)');
  const isSectionTwoOrBeyond = scrollPosition > window.innerHeight;
  const navigate = useNavigate();

  //#endregion

  //#region Navigation Links Data
  const navLinks = [
    { label: 'Home', path: '/' },
    { label: 'Projects', path: '/projects' },
    { label: 'About', path: '/#aboutSection' },
    { label: 'Profile', path: '/#profileSection' },
    { label: 'Contact', path: '/#contactSection' },
  ];
  //#endregion

  //#region Scroll and Menu Handlers

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      setScrollPosition(currentPosition);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMenuClick = () => {
    setMenuOpen((prev) => !prev);
    document.body.style.overflow = menuOpen ? 'auto' : 'hidden'; // Toggle scrolling
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    document.body.style.overflow = 'auto'; // Enable scrolling when menu closes
  };

  //#endregion

  //#region Request Popup Handlers
  const handleOpenRequest = () => setRequestpopUp(true);
  const handleCloseRequest = () => setRequestpopUp(false);
  //#endregion

  //#region Dynamic Styling Calculations

  const calculateBackgroundColor = () => {
    if (menuOpen) {
      return '#000'; // Black background when menu is open
    }

    const sectionHeight = window.innerHeight;
    const firstSectionEnd = sectionHeight;

    const currentPage = window.location.pathname;

    if (currentPage === '/projects') {
      if (scrollPosition <= sectionHeight) {
        const opacity = (6 * scrollPosition) / sectionHeight;
        return `rgba(242, 242, 242, ${opacity})`;
      }
    }

    if (currentPage === '/projects') {
      return '#000';
    }

    if (scrollPosition <= firstSectionEnd) {
      const opacity = (6 * scrollPosition) / sectionHeight;
      return `rgba(0,0,0, ${opacity})`;
    }

    const sectionIndex = Math.floor(scrollPosition / sectionHeight);
    return sectionIndex % 2 === 0 ? '#000' : '#f7f7f7';
  };

  const calculateNavbarHeight = () => {
    const sectionEnd = window.innerHeight;
    if (scrollPosition <= sectionEnd) {
      const height = 110 - (scrollPosition / sectionEnd) * 40;
      return `${Math.max(height, 70)}px`;
    }
    return '70px';
  };

  const calculateIconColor = () => {
    if (menuOpen) return '#fff';

    const sectionHeight = window.innerHeight;
    const sectionIndex = Math.floor(scrollPosition / sectionHeight);

    if (
      window.location.pathname === '/projects' &&
      scrollPosition <= sectionHeight
    ) {
      return '#000';
    }
    const currentPage = window.location.pathname;

    if (currentPage === '/projects') {
      return '#fff';
    }

    return sectionIndex % 2 === 0 ? '#fff' : '#000';
  };

  const calculateBoxShadow = () => {
    return window.location.pathname === '/projects'
      ? 'none'
      : '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)';
  };

  const calculateLogo = () => {
    const sectionIndex = Math.floor(scrollPosition / window.innerHeight);

    if (menuOpen) {
      return `${process.env.PUBLIC_URL}/images/Nblogowhite.png`;
    }

    const currentPage = window.location.pathname;

    if (currentPage === '/projects') {
      return sectionIndex % 2 === 0
        ? `${process.env.PUBLIC_URL}/images/Nblogoblack.png`
        : `${process.env.PUBLIC_URL}/images/Nblogowhite.png`;
    }
    return sectionIndex % 2 === 0
      ? `${process.env.PUBLIC_URL}/images/Nblogowhite.png`
      : `${process.env.PUBLIC_URL}/images/Nblogoblack.png`;
  };

  //#endregion

  return (
    <>
      <StyledAppBar
        position="fixed"
        sx={{
          backgroundColor: calculateBackgroundColor(),
          height: calculateNavbarHeight(),
          boxShadow: calculateBoxShadow(),
        }}
      >
        <Toolbar
          sx={{
            height: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '40px !important',
          }}
        >
          <img
            src={calculateLogo()}
            alt="logo"
            style={{ height: '45px', cursor: 'pointer', marginBottom: '10px' }}
            onClick={() => navigate('/')}
          />

          {isMobile || isSectionTwoOrBeyond ? (
            <IconButton
              edge="end"
              onClick={handleMenuClick}
              sx={{
                color: calculateIconColor(),
                transition: 'transform 0.3s ease-in-out',
                transform: menuOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                cursor: 'pointer',
              }}
            >
              {menuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          ) : (
            <div className="nav-links">
              {navLinks.map((link, index) => (
                <Button
                  key={index}
                  sx={{ color: calculateIconColor() }}
                  onClick={() => {
                    const isHashLink = link.path.includes('#'); // Check if it's a hash link
                    const [path, hash] = link.path.split('#'); // Split the path and hash if it exists

                    if (isHashLink) {
                      // If the link includes a hash, navigate to the new page
                      navigate(path); // Navigate to the page
                      setTimeout(() => {
                        const targetSection = document.querySelector(
                          `#${hash}`
                        );
                        if (targetSection) {
                          targetSection.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the section
                        }
                      }, 100); // Delay for a brief moment to allow the page to load before scrolling
                    } else {
                      navigate(link.path); // Navigate to the other page directly
                    }
                    handleMenuClose();
                  }}
                >
                  {link.label}
                </Button>
              ))}
            </div>
          )}
        </Toolbar>
      </StyledAppBar>

      {menuOpen && (
        <div
          className="menuContainer"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100%',
            backgroundColor: 'rgba(31, 31, 31, 0.99)',
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className="menu word">MENU</div>

          {!hideRequest && (
            <div className="popupLinks address">
              <div className="emailContent">
                <div className="text">
                  General Inquiries and Collaborations:
                </div>
                <div className="email">nidal.wdargham@gmail.com</div>
                <div className="text" style={{ marginBottom: '50px' }}>
                  For each project, the approach rests on a careful
                  understanding of the space.
                </div>
                <Button onClick={handleOpenRequest} className="requestButton">
                  Send Request
                </Button>
              </div>
            </div>
          )}

          <div className="popupLinks">
            <Typography variant="h3" sx={{ color: '#fff' }}>
              <div className="nav-links navlinksPopUp ">
                {navLinks.map((link, index) => (
                  <Button
                    key={index}
                    className="navlinkdetailspopup"
                    sx={{ color: calculateIconColor() }}
                    onClick={() => {
                      const isHashLink = link.path.includes('#'); // Check if it's a hash link
                      const [path, hash] = link.path.split('#'); // Split the path and hash if it exists

                      if (isHashLink) {
                        // If the link includes a hash, navigate to the new page
                        navigate(path); // Navigate to the page
                        setTimeout(() => {
                          const targetSection = document.querySelector(
                            `#${hash}`
                          );
                          if (targetSection) {
                            targetSection.scrollIntoView({
                              behavior: 'smooth',
                            }); // Smooth scroll to the section
                          }
                        }, 100); // Delay for a brief moment to allow the page to load before scrolling
                      } else {
                        navigate(link.path); // Navigate to the other page directly
                      }
                      handleMenuClose();
                    }}
                  >
                    {link.label}
                  </Button>
                ))}
              </div>
            </Typography>
          </div>

          <RequestPopup isOpen={requestpopUp} onClose={handleCloseRequest} />
        </div>
      )}
    </>
  );
};

const StyledAppBar = styled(AppBar)({
  transition: 'background-color 0.3s ease-in-out, height 0.3s ease-in-out',
});

export default Navbar;

const data = [
  {
    id: '1',
    title: 'The Elegant Salon: A Touch of Luxury',
    // shortDescription:
    //   'A serene space focusing on simplicity and functionality.',
    // brief:
    //   'This project showcases a modern minimalist living room designed to create a calming atmosphere through a neutral color palette and clean lines. The intent was to utilize natural materials and ample natural light to enhance the openness of the space, making it perfect for relaxation and social gatherings. Thoughtful selection of furniture and decor aimed to eliminate unnecessary clutter while providing comfort. Incorporating plants further softens the design and promotes well-being, making this space a true sanctuary. The furniture selection features pieces with simple geometric forms that add elegance without overpowering the room, encouraging a peaceful vibe conducive to unwinding after a long day or hosting friends for an evening gathering.',
    // challenge:
    //   "The main challenge was to incorporate storage solutions without cluttering the aesthetic. The design required a careful balance between functionality and the minimalist ethos. To address this, we focused on creating built-in furniture that blends seamlessly with the decor while offering ample storage. The objective was to avoid bulky, distracting furniture that could compromise the clean lines of the design. Collaboration with skilled craftsmen ensured that the storage solutions were not only functional but also visually appealing. The use of concealed compartments and multifunctional furniture helped in maintaining the room's spacious feel, all while ensuring that the living space remained organized and free from visual chaos, meeting the project's core goals.",
    // layout:
    //   'The layout features an open plan with the living area seamlessly flowing into the dining space, fostering a sense of community and connection. The arrangement of furniture facilitates movement while maximizing the use of space, creating zones for different activities without physical barriers. A large sofa is centrally positioned to invite conversation, while a minimalist coffee table anchors the space. The layout encourages social interaction, with easy access to the dining area for gatherings. This fluidity is further enhanced by strategic lighting placements, ensuring that each zone feels inviting and cohesive. Additionally, the design accounts for flow and circulation, allowing residents and guests to move effortlessly between spaces, which enhances the overall usability of the home.',
    // concept:
    //   "The design concept centers on the idea of 'less is more.' By prioritizing essential elements, the project achieves a sophisticated look that feels both spacious and inviting. The guiding philosophy is rooted in the belief that simplicity can create beauty, focusing on a few high-quality pieces rather than overwhelming the space with decor. This approach allows each element to shine and encourages a mindful living experience. The interplay of natural light and textures further elevates the ambiance, creating a warm yet modern environment. The concept not only embraces aesthetics but also sustainability, encouraging the use of eco-friendly materials and practices throughout the design process. This holistic approach culminates in a living space that embodies tranquility and contemporary elegance.",
    mainImage: 'projects/Project1-1.jpg',
    images: [
      'projects/Project1-1.jpg',
      'projects/Project1-2.jpg',
      'projects/project1-3.jpg',
      'projects/project1-4.jpg',
    ],
    link: 'https://example.com/modern-minimalist-living-room',
    category: 'salons',
  },
  {
    id: '2',
    title: 'Timber Touch: Creating an Inviting Atmosphere',
    // shortDescription:
    //   'A stylish workspace that embraces an industrial aesthetic.',
    // brief:
    //   'This project reimagines a traditional office space into an industrial chic environment that fosters creativity and productivity. Exposed brick walls, metal fixtures, and reclaimed wood furniture create a unique atmosphere that balances professionalism with comfort. The design aims to inspire innovation while providing the necessary amenities for modern work demands. The choice of furnishings and decor emphasizes a rugged yet refined aesthetic, which not only serves functional purposes but also promotes a sense of community among coworkers. The color palette consists of earthy tones complemented by vibrant accents, contributing to an invigorating environment where ideas can flourish. This design exemplifies how aesthetic choices can enhance employee well-being and productivity.',
    // challenge:
    //   'Integrating modern technology within the industrial aesthetic posed a challenge, as it was essential to maintain the raw, authentic vibe of the space while accommodating the needs of a contemporary office. The solution involved careful selection of tech elements that complemented the materials used throughout the design. For instance, wiring and cords were cleverly concealed to prevent disruption of the visual flow, ensuring that the industrial charm remained intact. Additionally, creating comfortable workstations that did not detract from the aesthetic was crucial. By collaborating with tech experts, we implemented innovative solutions that allowed for seamless integration of technology while preserving the character of the space, ultimately achieving a harmonious balance between old and new.',
    // layout:
    //   'The layout encourages collaboration with open desk spaces and cozy meeting areas, promoting interaction among team members. The design features an adaptable workspace that includes designated zones for both focused work and collaborative projects. Strategic placement of furniture creates a sense of flow while maintaining privacy where necessary, with areas for informal meetings equipped with comfortable seating. The use of partitions made from reclaimed materials enhances the aesthetic while providing functional separation without isolating individuals. By incorporating elements such as a communal lounge area, the layout fosters a vibrant, engaging environment that motivates employees to connect and share ideas, thus enhancing overall workplace dynamics and productivity.',
    // concept:
    //   "The concept revolves around merging vintage industrial elements with modern functionality, emphasizing the importance of creating a space that is both aesthetically pleasing and practical. This approach invites the character of the building's history into the design, allowing it to shine through while catering to contemporary needs. The use of color, materials, and textures aims to create an environment that stimulates creativity and enhances focus. By juxtaposing warm and cool tones, the design maintains a dynamic energy that resonates throughout the space. The concept is not only about the visual impact; it encompasses the experience of working within the space, prioritizing comfort and functionality to inspire employees and drive success.",
    mainImage: 'projects/Project2-1.jpg',
    images: [
      'projects/Project2-1.jpg',
      'projects/Project2-2.jpg',
      'projects/project2-3.jpg',
      'projects/project2-4.jpg',
    ],
    link: 'https://example.com/industrial-chic-office-space',
    category: 'livingRoom',
  },
  {
    id: '3',
    title: 'A Radiant Salon Experience in Urban Living',
    shortDescription: 'A vibrant bedroom infused with eclectic bohemian charm.',
    brief:
      "This bedroom project embraces bohemian style, characterized by bold patterns, rich colors, and an array of textures. The design features layered textiles and artisanal decor, creating a warm and inviting sanctuary for relaxation. Each element is thoughtfully selected to tell a story and reflect the occupant's personality, resulting in a space that feels both intimate and unique. The combination of different styles and influences celebrates individuality while maintaining a cohesive look. This project is an exploration of how personal expression can transform a space into a personal haven. The use of natural materials and handmade items adds character and depth to the overall design, enriching the aesthetic experience.",
    challenge:
      "The main challenge was to achieve a cohesive look with a variety of patterns and colors, which can often lead to visual chaos. Careful curation of textiles and decor ensured harmony while allowing for personal expression. Selecting pieces that complemented one another in style and color was essential to avoid overwhelming the senses. Additionally, the spatial arrangement needed to promote a sense of comfort and relaxation without feeling cluttered. This was accomplished by strategically layering textiles and creating zones within the room, such as a reading nook. Collaborating with artisans and local craftspeople allowed for unique, bespoke items that contributed to the space's individuality while maintaining the overall theme.",
    layout:
      'The layout is designed for comfort, with a large bed as the focal point surrounded by various seating options, including cozy floor cushions and a vintage armchair. This arrangement encourages relaxation and social interaction, making the room feel welcoming. By placing the bed in a way that maximizes natural light and views, the design enhances the overall ambiance. Additionally, incorporating shelves and storage solutions that blend with the decor keeps the space organized and functional. The careful placement of rugs helps to define different areas within the room, enhancing the feeling of warmth and intimacy. Overall, the layout is carefully crafted to create a sanctuary that invites rest and personal reflection.',
    concept:
      'The concept highlights individualism and comfort, allowing the space to reflect personal style while providing a relaxing retreat from the outside world. The design embraces a free-spirited approach, celebrating the beauty of eclecticism while ensuring a sense of balance and coherence. By blending various cultural influences and materials, the space becomes a canvas for self-expression. The use of vibrant colors and intricate patterns is meant to evoke a sense of joy and creativity, transforming the bedroom into a true sanctuary. Additionally, the concept emphasizes sustainability, encouraging the use of eco-friendly materials and artisanal craftsmanship. This holistic approach culminates in a bedroom that is both visually stunning and deeply personal, embodying the essence of bohemian living.',
    mainImage: 'projects/Project3-1.jpg',
    images: [
      'projects/Project3-1.jpg',
      'projects/Project3-2.jpg',
      'projects/project3-3.jpg',
      'projects/project3-4.jpg',
    ],
    link: 'https://example.com/cozy-bohemian-bedroom',
    category: 'salons',
  },
  {
    id: '4',
    title: 'Whispers of Nature',
    // shortDescription:
    //   'A serene space focusing on simplicity and functionality.',
    // brief:
    //   'This project showcases a modern minimalist living room designed to create a calming atmosphere through a neutral color palette and clean lines. The intent was to utilize natural materials and ample natural light to enhance the openness of the space, making it perfect for relaxation and social gatherings. Thoughtful selection of furniture and decor aimed to eliminate unnecessary clutter while providing comfort. Incorporating plants further softens the design and promotes well-being, making this space a true sanctuary. The furniture selection features pieces with simple geometric forms that add elegance without overpowering the room, encouraging a peaceful vibe conducive to unwinding after a long day or hosting friends for an evening gathering.',
    // challenge:
    //   "The main challenge was to incorporate storage solutions without cluttering the aesthetic. The design required a careful balance between functionality and the minimalist ethos. To address this, we focused on creating built-in furniture that blends seamlessly with the decor while offering ample storage. The objective was to avoid bulky, distracting furniture that could compromise the clean lines of the design. Collaboration with skilled craftsmen ensured that the storage solutions were not only functional but also visually appealing. The use of concealed compartments and multifunctional furniture helped in maintaining the room's spacious feel, all while ensuring that the living space remained organized and free from visual chaos, meeting the project's core goals.",
    // layout:
    //   'The layout features an open plan with the living area seamlessly flowing into the dining space, fostering a sense of community and connection. The arrangement of furniture facilitates movement while maximizing the use of space, creating zones for different activities without physical barriers. A large sofa is centrally positioned to invite conversation, while a minimalist coffee table anchors the space. The layout encourages social interaction, with easy access to the dining area for gatherings. This fluidity is further enhanced by strategic lighting placements, ensuring that each zone feels inviting and cohesive. Additionally, the design accounts for flow and circulation, allowing residents and guests to move effortlessly between spaces, which enhances the overall usability of the home.',
    // concept:
    //   "The design concept centers on the idea of 'less is more.' By prioritizing essential elements, the project achieves a sophisticated look that feels both spacious and inviting. The guiding philosophy is rooted in the belief that simplicity can create beauty, focusing on a few high-quality pieces rather than overwhelming the space with decor. This approach allows each element to shine and encourages a mindful living experience. The interplay of natural light and textures further elevates the ambiance, creating a warm yet modern environment. The concept not only embraces aesthetics but also sustainability, encouraging the use of eco-friendly materials and practices throughout the design process. This holistic approach culminates in a living space that embodies tranquility and contemporary elegance.",
    mainImage: 'projects/Project4-1.jpg',
    images: [
      'projects/Project4-1.jpg',
      'projects/project4-3.jpg',
      'projects/project4-4.jpg',
      'projects/Project4-2.jpg',
    ],
    link: 'https://example.com/modern-minimalist-living-room',
    category: 'salons',
  },
  {
    id: '5',
    title: 'Soft Palette: A Refreshing Bedroom in Soft Hue',
    // shortDescription:
    //   'A stylish workspace that embraces an industrial aesthetic.',
    // brief:
    //   'This project reimagines a traditional office space into an industrial chic environment that fosters creativity and productivity. Exposed brick walls, metal fixtures, and reclaimed wood furniture create a unique atmosphere that balances professionalism with comfort. The design aims to inspire innovation while providing the necessary amenities for modern work demands. The choice of furnishings and decor emphasizes a rugged yet refined aesthetic, which not only serves functional purposes but also promotes a sense of community among coworkers. The color palette consists of earthy tones complemented by vibrant accents, contributing to an invigorating environment where ideas can flourish. This design exemplifies how aesthetic choices can enhance employee well-being and productivity.',
    // challenge:
    //   'Integrating modern technology within the industrial aesthetic posed a challenge, as it was essential to maintain the raw, authentic vibe of the space while accommodating the needs of a contemporary office. The solution involved careful selection of tech elements that complemented the materials used throughout the design. For instance, wiring and cords were cleverly concealed to prevent disruption of the visual flow, ensuring that the industrial charm remained intact. Additionally, creating comfortable workstations that did not detract from the aesthetic was crucial. By collaborating with tech experts, we implemented innovative solutions that allowed for seamless integration of technology while preserving the character of the space, ultimately achieving a harmonious balance between old and new.',
    // layout:
    //   'The layout encourages collaboration with open desk spaces and cozy meeting areas, promoting interaction among team members. The design features an adaptable workspace that includes designated zones for both focused work and collaborative projects. Strategic placement of furniture creates a sense of flow while maintaining privacy where necessary, with areas for informal meetings equipped with comfortable seating. The use of partitions made from reclaimed materials enhances the aesthetic while providing functional separation without isolating individuals. By incorporating elements such as a communal lounge area, the layout fosters a vibrant, engaging environment that motivates employees to connect and share ideas, thus enhancing overall workplace dynamics and productivity.',
    // concept:
    //   "The concept revolves around merging vintage industrial elements with modern functionality, emphasizing the importance of creating a space that is both aesthetically pleasing and practical. This approach invites the character of the building's history into the design, allowing it to shine through while catering to contemporary needs. The use of color, materials, and textures aims to create an environment that stimulates creativity and enhances focus. By juxtaposing warm and cool tones, the design maintains a dynamic energy that resonates throughout the space. The concept is not only about the visual impact; it encompasses the experience of working within the space, prioritizing comfort and functionality to inspire employees and drive success.",
    mainImage: 'projects/Project5-1.jpg',
    images: [
      'projects/Project5-1.jpg',
      'projects/Project5-2.jpg',
      'projects/project5-3.jpg',
      'projects/project5-4.jpg',
    ],
    link: 'https://example.com/industrial-chic-office-space',
    category: 'bedrooms',
  },
  {
    id: '6',
    title: 'Minimalist Luxury: Redesigning Toilets for the Modern Home',
    // shortDescription: 'A vibrant bedroom infused with eclectic bohemian charm.',
    // brief:
    //   "This bedroom project embraces bohemian style, characterized by bold patterns, rich colors, and an array of textures. The design features layered textiles and artisanal decor, creating a warm and inviting sanctuary for relaxation. Each element is thoughtfully selected to tell a story and reflect the occupant's personality, resulting in a space that feels both intimate and unique. The combination of different styles and influences celebrates individuality while maintaining a cohesive look. This project is an exploration of how personal expression can transform a space into a personal haven. The use of natural materials and handmade items adds character and depth to the overall design, enriching the aesthetic experience.",
    // challenge:
    //   "The main challenge was to achieve a cohesive look with a variety of patterns and colors, which can often lead to visual chaos. Careful curation of textiles and decor ensured harmony while allowing for personal expression. Selecting pieces that complemented one another in style and color was essential to avoid overwhelming the senses. Additionally, the spatial arrangement needed to promote a sense of comfort and relaxation without feeling cluttered. This was accomplished by strategically layering textiles and creating zones within the room, such as a reading nook. Collaborating with artisans and local craftspeople allowed for unique, bespoke items that contributed to the space's individuality while maintaining the overall theme.",
    // layout:
    //   'The layout is designed for comfort, with a large bed as the focal point surrounded by various seating options, including cozy floor cushions and a vintage armchair. This arrangement encourages relaxation and social interaction, making the room feel welcoming. By placing the bed in a way that maximizes natural light and views, the design enhances the overall ambiance. Additionally, incorporating shelves and storage solutions that blend with the decor keeps the space organized and functional. The careful placement of rugs helps to define different areas within the room, enhancing the feeling of warmth and intimacy. Overall, the layout is carefully crafted to create a sanctuary that invites rest and personal reflection.',
    // concept:
    //   'The concept highlights individualism and comfort, allowing the space to reflect personal style while providing a relaxing retreat from the outside world. The design embraces a free-spirited approach, celebrating the beauty of eclecticism while ensuring a sense of balance and coherence. By blending various cultural influences and materials, the space becomes a canvas for self-expression. The use of vibrant colors and intricate patterns is meant to evoke a sense of joy and creativity, transforming the bedroom into a true sanctuary. Additionally, the concept emphasizes sustainability, encouraging the use of eco-friendly materials and artisanal craftsmanship. This holistic approach culminates in a bedroom that is both visually stunning and deeply personal, embodying the essence of bohemian living.',
    mainImage: 'projects/Project6-1.jpg',
    images: [
      'projects/Project6-1.jpg',
      'projects/Project6-2.jpg',
      'projects/project6-3.jpg',
      'projects/project6-4.jpg',
    ],
    link: 'https://example.com/cozy-bohemian-bedroom',
    category: 'toilets',
  },
  {
    id: '7',
    title: 'Pure Comfort A White Corner for Cozy Living',
    // shortDescription:
    //   'A serene space focusing on simplicity and functionality.',
    // brief:
    //   'This project showcases a modern minimalist living room designed to create a calming atmosphere through a neutral color palette and clean lines. The intent was to utilize natural materials and ample natural light to enhance the openness of the space, making it perfect for relaxation and social gatherings. Thoughtful selection of furniture and decor aimed to eliminate unnecessary clutter while providing comfort. Incorporating plants further softens the design and promotes well-being, making this space a true sanctuary. The furniture selection features pieces with simple geometric forms that add elegance without overpowering the room, encouraging a peaceful vibe conducive to unwinding after a long day or hosting friends for an evening gathering.',
    // challenge:
    //   "The main challenge was to incorporate storage solutions without cluttering the aesthetic. The design required a careful balance between functionality and the minimalist ethos. To address this, we focused on creating built-in furniture that blends seamlessly with the decor while offering ample storage. The objective was to avoid bulky, distracting furniture that could compromise the clean lines of the design. Collaboration with skilled craftsmen ensured that the storage solutions were not only functional but also visually appealing. The use of concealed compartments and multifunctional furniture helped in maintaining the room's spacious feel, all while ensuring that the living space remained organized and free from visual chaos, meeting the project's core goals.",
    // layout:
    //   'The layout features an open plan with the living area seamlessly flowing into the dining space, fostering a sense of community and connection. The arrangement of furniture facilitates movement while maximizing the use of space, creating zones for different activities without physical barriers. A large sofa is centrally positioned to invite conversation, while a minimalist coffee table anchors the space. The layout encourages social interaction, with easy access to the dining area for gatherings. This fluidity is further enhanced by strategic lighting placements, ensuring that each zone feels inviting and cohesive. Additionally, the design accounts for flow and circulation, allowing residents and guests to move effortlessly between spaces, which enhances the overall usability of the home.',
    // concept:
    //   "The design concept centers on the idea of 'less is more.' By prioritizing essential elements, the project achieves a sophisticated look that feels both spacious and inviting. The guiding philosophy is rooted in the belief that simplicity can create beauty, focusing on a few high-quality pieces rather than overwhelming the space with decor. This approach allows each element to shine and encourages a mindful living experience. The interplay of natural light and textures further elevates the ambiance, creating a warm yet modern environment. The concept not only embraces aesthetics but also sustainability, encouraging the use of eco-friendly materials and practices throughout the design process. This holistic approach culminates in a living space that embodies tranquility and contemporary elegance.",
    mainImage: 'projects/Project7-1.jpg',
    images: [
      'projects/Project7-1.jpg',
      'projects/Project7-2.jpg',
      'projects/project7-3.jpg',
      'projects/project7-4.jpg',
    ],
    link: 'https://example.com/modern-minimalist-living-room',
    category: 'livingRoom',
  },
  {
    id: '8',
    title: 'Restful Reflections: A Modern Dining Space with Peaceful Vibes',
    // shortDescription:
    //   'A stylish workspace that embraces an industrial aesthetic.',
    // brief:
    //   'This project reimagines a traditional office space into an industrial chic environment that fosters creativity and productivity. Exposed brick walls, metal fixtures, and reclaimed wood furniture create a unique atmosphere that balances professionalism with comfort. The design aims to inspire innovation while providing the necessary amenities for modern work demands. The choice of furnishings and decor emphasizes a rugged yet refined aesthetic, which not only serves functional purposes but also promotes a sense of community among coworkers. The color palette consists of earthy tones complemented by vibrant accents, contributing to an invigorating environment where ideas can flourish. This design exemplifies how aesthetic choices can enhance employee well-being and productivity.',
    // challenge:
    //   'Integrating modern technology within the industrial aesthetic posed a challenge, as it was essential to maintain the raw, authentic vibe of the space while accommodating the needs of a contemporary office. The solution involved careful selection of tech elements that complemented the materials used throughout the design. For instance, wiring and cords were cleverly concealed to prevent disruption of the visual flow, ensuring that the industrial charm remained intact. Additionally, creating comfortable workstations that did not detract from the aesthetic was crucial. By collaborating with tech experts, we implemented innovative solutions that allowed for seamless integration of technology while preserving the character of the space, ultimately achieving a harmonious balance between old and new.',
    // layout:
    //   'The layout encourages collaboration with open desk spaces and cozy meeting areas, promoting interaction among team members. The design features an adaptable workspace that includes designated zones for both focused work and collaborative projects. Strategic placement of furniture creates a sense of flow while maintaining privacy where necessary, with areas for informal meetings equipped with comfortable seating. The use of partitions made from reclaimed materials enhances the aesthetic while providing functional separation without isolating individuals. By incorporating elements such as a communal lounge area, the layout fosters a vibrant, engaging environment that motivates employees to connect and share ideas, thus enhancing overall workplace dynamics and productivity.',
    // concept:
    //   "The concept revolves around merging vintage industrial elements with modern functionality, emphasizing the importance of creating a space that is both aesthetically pleasing and practical. This approach invites the character of the building's history into the design, allowing it to shine through while catering to contemporary needs. The use of color, materials, and textures aims to create an environment that stimulates creativity and enhances focus. By juxtaposing warm and cool tones, the design maintains a dynamic energy that resonates throughout the space. The concept is not only about the visual impact; it encompasses the experience of working within the space, prioritizing comfort and functionality to inspire employees and drive success.",
    mainImage: 'projects/Project8-1.jpg',
    images: [
      'projects/Project8-1.jpg',
      'projects/Project8-2.jpg',
      'projects/project8-3.jpg',
      'projects/project8-4.jpg',
    ],
    link: 'https://example.com/industrial-chic-office-space',
    category: 'salons',
  },
  {
    id: '9',
    title: 'A Serene First-Floor Bedroom Experience',
    // shortDescription: 'A vibrant bedroom infused with eclectic bohemian charm.',
    // brief:
    //   "This bedroom project embraces bohemian style, characterized by bold patterns, rich colors, and an array of textures. The design features layered textiles and artisanal decor, creating a warm and inviting sanctuary for relaxation. Each element is thoughtfully selected to tell a story and reflect the occupant's personality, resulting in a space that feels both intimate and unique. The combination of different styles and influences celebrates individuality while maintaining a cohesive look. This project is an exploration of how personal expression can transform a space into a personal haven. The use of natural materials and handmade items adds character and depth to the overall design, enriching the aesthetic experience.",
    // challenge:
    //   "The main challenge was to achieve a cohesive look with a variety of patterns and colors, which can often lead to visual chaos. Careful curation of textiles and decor ensured harmony while allowing for personal expression. Selecting pieces that complemented one another in style and color was essential to avoid overwhelming the senses. Additionally, the spatial arrangement needed to promote a sense of comfort and relaxation without feeling cluttered. This was accomplished by strategically layering textiles and creating zones within the room, such as a reading nook. Collaborating with artisans and local craftspeople allowed for unique, bespoke items that contributed to the space's individuality while maintaining the overall theme.",
    // layout:
    //   'The layout is designed for comfort, with a large bed as the focal point surrounded by various seating options, including cozy floor cushions and a vintage armchair. This arrangement encourages relaxation and social interaction, making the room feel welcoming. By placing the bed in a way that maximizes natural light and views, the design enhances the overall ambiance. Additionally, incorporating shelves and storage solutions that blend with the decor keeps the space organized and functional. The careful placement of rugs helps to define different areas within the room, enhancing the feeling of warmth and intimacy. Overall, the layout is carefully crafted to create a sanctuary that invites rest and personal reflection.',
    // concept:
    //   'The concept highlights individualism and comfort, allowing the space to reflect personal style while providing a relaxing retreat from the outside world. The design embraces a free-spirited approach, celebrating the beauty of eclecticism while ensuring a sense of balance and coherence. By blending various cultural influences and materials, the space becomes a canvas for self-expression. The use of vibrant colors and intricate patterns is meant to evoke a sense of joy and creativity, transforming the bedroom into a true sanctuary. Additionally, the concept emphasizes sustainability, encouraging the use of eco-friendly materials and artisanal craftsmanship. This holistic approach culminates in a bedroom that is both visually stunning and deeply personal, embodying the essence of bohemian living.',
    mainImage: 'projects/Project9-1.jpg',
    images: [
      'projects/Project9-1.jpg',
      'projects/Project9-2.jpg',
      'projects/project9-3.jpg',
      'projects/project9-4.jpg',
    ],
    link: 'https://example.com/cozy-bohemian-bedroom',
    category: 'salons',
  },
  {
    id: '10',
    title: 'Fresh Perspectives: An Open Villa Living and Dining Oasis',
    // shortDescription: 'A vibrant bedroom infused with eclectic bohemian charm.',
    // brief:
    //   "This bedroom project embraces bohemian style, characterized by bold patterns, rich colors, and an array of textures. The design features layered textiles and artisanal decor, creating a warm and inviting sanctuary for relaxation. Each element is thoughtfully selected to tell a story and reflect the occupant's personality, resulting in a space that feels both intimate and unique. The combination of different styles and influences celebrates individuality while maintaining a cohesive look. This project is an exploration of how personal expression can transform a space into a personal haven. The use of natural materials and handmade items adds character and depth to the overall design, enriching the aesthetic experience.",
    // challenge:
    //   "The main challenge was to achieve a cohesive look with a variety of patterns and colors, which can often lead to visual chaos. Careful curation of textiles and decor ensured harmony while allowing for personal expression. Selecting pieces that complemented one another in style and color was essential to avoid overwhelming the senses. Additionally, the spatial arrangement needed to promote a sense of comfort and relaxation without feeling cluttered. This was accomplished by strategically layering textiles and creating zones within the room, such as a reading nook. Collaborating with artisans and local craftspeople allowed for unique, bespoke items that contributed to the space's individuality while maintaining the overall theme.",
    // layout:
    //   'The layout is designed for comfort, with a large bed as the focal point surrounded by various seating options, including cozy floor cushions and a vintage armchair. This arrangement encourages relaxation and social interaction, making the room feel welcoming. By placing the bed in a way that maximizes natural light and views, the design enhances the overall ambiance. Additionally, incorporating shelves and storage solutions that blend with the decor keeps the space organized and functional. The careful placement of rugs helps to define different areas within the room, enhancing the feeling of warmth and intimacy. Overall, the layout is carefully crafted to create a sanctuary that invites rest and personal reflection.',
    // concept:
    //   'The concept highlights individualism and comfort, allowing the space to reflect personal style while providing a relaxing retreat from the outside world. The design embraces a free-spirited approach, celebrating the beauty of eclecticism while ensuring a sense of balance and coherence. By blending various cultural influences and materials, the space becomes a canvas for self-expression. The use of vibrant colors and intricate patterns is meant to evoke a sense of joy and creativity, transforming the bedroom into a true sanctuary. Additionally, the concept emphasizes sustainability, encouraging the use of eco-friendly materials and artisanal craftsmanship. This holistic approach culminates in a bedroom that is both visually stunning and deeply personal, embodying the essence of bohemian living.',
    mainImage: 'projects/Project10-1.jpg',
    images: [
      'projects/Project10-1.jpg',
      'projects/Project10-2.jpg',
      'projects/project10-3.jpg',
      'projects/project10-4.jpg',
    ],
    link: 'https://example.com/cozy-bohemian-bedroom',
    category: 'salons',
  },
];

export default data;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Navbar from './components/NavBar';
import Footer from './components/Footer';
import DetailPage from './pages/DetailPage';
import { footerData } from './data/footerData';
import { handleDownloadCV } from './utils';

const App = () => {
  return (
    <div>
      <div
        style={{
          position: 'fixed',
          bottom: '25px',
          right: 0,
          zIndex: 1000,
          backgroundColor: 'black',
          height: '180px',
          width: '50px',
          display: 'flex',
          flexDirection: 'column',
          color: 'white',
          justifyContent: 'space-around',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => handleDownloadCV()}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/Nbwhite.png`}
          alt="logo"
          style={{ height: '20px', padding: '1px' }}
        />{' '}
        <div
          style={{
            rotate: '270deg',
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '15px',
          }}
        >
          Resume
        </div>
      </div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/details/:title/:id" element={<DetailPage />} />
      </Routes>
      <Footer footerData={footerData} />
    </div>
  );
};

export default App;

export const footerData = {
  companyName: 'NB Interiors',
  companyTagline:
    'Crafting unique and personalized spaces that resonate with your lifestyle.',
  navLinks: [
    { label: 'Home', path: '/' },
    { label: 'Projects', path: '/projects' },
    { label: 'About', path: '/#aboutSection' },
    { label: 'Profile', path: '/#profileSection' },
    { label: 'Contact', path: '/#contactSection' },
  ],
  developerText: 'Developed by',
  developerLogo: 'images/Visionary Vortex  white-01.png',
  copyrightText: 'Nb. Interiors',
};

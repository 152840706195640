import React from 'react';
import { useNavigate } from 'react-router-dom';

const Categories = () => {
  const navigate = useNavigate();
  return (
    <div className="categories">
      <div
        className="category"
        style={{
          backgroundImage: `url('./projects/Project5-1.jpg')`,
          backgroundPosition: 'left center',
          backgroundSize: 'cover',
          cursor: 'pointer',
        }}
      >
        <div
          className="content"
          style={{ width: '100%' }}
          onClick={() =>
            navigate('/projects', { state: { selectedFilter: 'bedrooms' } })
          }
        >
          Dream Haven
        </div>
      </div>

      <div
        className="category"
        style={{
          backgroundImage: `url('./projects/Project2-1.jpg')`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          cursor: 'pointer',
        }}
        onClick={() =>
          navigate('/projects', { state: { selectedFilter: 'livingRoom' } })
        }
      >
        <div className="content" style={{ width: '100%' }}>
          Serenity Haven
        </div>
      </div>

      <div
        className="category"
        style={{
          backgroundImage: `url('./projects/Project10-1.jpg')`,
          backgroundPosition: 'right center',
          backgroundSize: 'cover',
          cursor: 'pointer',
        }}
        onClick={() =>
          navigate('/projects', { state: { selectedFilter: 'salons' } })
        }
      >
        <div className="content" style={{ width: '100%' }}>
          Glam Haven
        </div>
      </div>
    </div>
  );
};

export default Categories;

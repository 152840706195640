import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import data from '../data/data';

const DetailPage = () => {
  const { id } = useParams();
  const item = data.find((img) => img.id === id);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (!item) {
    return <div>Image not found</div>;
  }

  return (
    <div style={{ padding: '0px' }}>
      <div className="container__slider">
        <img
          className="slider__image"
          src={`${process.env.PUBLIC_URL}/${item.mainImage}`}
          alt={item.title}
          style={{
            width: '100%',
            height: '80vh',
            objectFit: 'cover',
            position: 'relative',
          }}
        />
        <div
          className="content"
          style={{
            position: 'absolute',
            top: '30%',
            left: '15%',
            color: '#f7f7f7',
            margin: 'auto',
          }}
        >
          <div className="sliderTitle">{item.title}</div>
          <div className="slider__link" style={{ letterSpacing: '1px' }}>
            {item.shortDescription}
          </div>
        </div>
      </div>

      {/* To be discussed later */}
      {/* <div className="brightSection brightDetailSection  ">
        <div
          className="word "
          style={{
            position: 'relative',
            color: '#f2f2f2',
            fontSize: '14vw',
            letterSpacing: '3vw',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            fontWeight: '500',
            height: '70vh',
          }}
        >
          A B O U T
        </div>

        <div
          className="aboutDetailsHolder"
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            width: '80%',
            right: '0',
            left: '0',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              alignItems: 'center',
            }}
          >
            <div
              className="briefTitle"
              style={{
                fontSize: '36px',
                fontWeight: '600',
                color: '#000',
                marginBottom: '25px',
                lineHeight: '40px',
                display: 'flex',
                textAlign: 'start',
                width: '73vw',
              }}
            >
              Brief
            </div>
            <div
              className="about aboutDetails"
              style={{
                width: '72.5vw',
                lineHeight: '30px',
              }}
            >
              {item.brief}
            </div>
          </div>
        </div>
      </div> */}

      {/* Image Section */}

      <div
        className="imgSection brightSection detailSection"
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center', // added
        }}
      >
        <div className="imageHolder" style={{ width: '50%', padding: '50px' }}>
          <img
            src={`${process.env.PUBLIC_URL}/${item.images[1]}`}
            alt={item.title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'relative',
            }}
          />
        </div>
        {/* <div
          className="contentHolder"
          style={{ width: '50%', padding: '40px' }}
        >
          <div
            className="contentDetails"
            style={{
              width: '100%',
              height: '100%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                className="detailTitle"
                style={{
                  fontSize: '36px',
                  fontWeight: '600',
                  color: '#000',
                  marginBottom: '25px',
                  lineHeight: '40px',
                  display: 'flex',
                }}
              >
                Challenge
              </div>
              <div
                className="about"
                style={{
                  width: '100%',
                  lineHeight: '30px',
                }}
              >
                {item.challenge}
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div
        className="imgSection brightSection detailSection"
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <div className="imageHolder" style={{ width: '50%', padding: '50px' }}>
          <img
            src={`${process.env.PUBLIC_URL}/${item.images[2]}`}
            alt={item.title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'relative',
            }}
          />
        </div>
        {/* <div
          className="contentHolder"
          style={{ width: '50%', padding: '40px' }}
        >
          <div
            className="contentDetails"
            style={{
              width: '100%',
              height: '100%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                className="detailTitle"
                style={{
                  fontSize: '36px',
                  fontWeight: '600',
                  color: '#000',
                  marginBottom: '25px',
                  lineHeight: '40px',
                  display: 'flex',
                }}
              >
                Layout
              </div>
              <div
                className="about"
                style={{
                  width: '100%',
                  lineHeight: '30px',
                }}
              >
                {item.layout}
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div
        className="imgSection brightSection detailSection"
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '100px',
          justifyContent: 'center',
        }}
      >
        <div className="imageHolder" style={{ width: '50%', padding: '50px' }}>
          <img
            src={`${process.env.PUBLIC_URL}/${item.images[3]}`}
            alt={item.title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'relative',
            }}
          />
        </div>
        {/* <div
          className="contentHolder"
          style={{ width: '50%', padding: '40px' }}
        >
          <div
            className="contentDetails"
            style={{
              width: '100%',
              height: '100%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                className="detailTitle"
                style={{
                  fontSize: '36px',
                  fontWeight: '600',
                  color: '#000',
                  marginBottom: '25px',
                  lineHeight: '40px',
                  display: 'flex',
                }}
              >
                Concept
              </div>
              <div
                className="about"
                style={{
                  width: '100%',
                  lineHeight: '30px',
                }}
              >
                {item.concept}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DetailPage;

import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const animateText = (text, animate) => {
  return text.split('').map((letter, index) => (
    <span
      key={index}
      className={`animated-letter ${animate ? 'animate' : ''}`}
      style={{ animationDelay: `${index * 0.2}s` }}
    >
      {letter === ' ' ? '\u00A0' : letter}
    </span>
  ));
};

const About = () => {
  const [aboutRef, aboutInView] = useInView({
    triggerOnce: false,
    threshold: 0.7, // Trigger when 70% of the section is visible
  });

  const [titleRef, titleInView] = useInView({
    triggerOnce: false,
    threshold: 0.9, // Trigger when 80% of the section is visible
  });

  const [paragraphsRef, paragraphsInView] = useInView({
    triggerOnce: false,
    threshold: 1, // Trigger when 90% of the section is visible
  });

  useEffect(() => {
    const letters = document.querySelectorAll('.animated-letter');
    if (aboutInView) {
      letters.forEach((letter) => {
        letter.classList.add('animate');
      });
    }
  }, [aboutInView]);

  useEffect(() => {
    const titleLetters = document.querySelectorAll('.title-letter');
    if (titleInView) {
      titleLetters.forEach((letter) => {
        letter.classList.add('animate');
      });
    }
  }, [titleInView]);

  useEffect(() => {
    const paragraphElements = document.querySelectorAll('.paragraph-letter');
    if (paragraphsInView) {
      paragraphElements.forEach((letter) => {
        letter.classList.add('animate');
      });
    }
  }, [paragraphsInView]);

  return (
    <div
      ref={aboutRef}
      className="brightSection brightSectionContact"
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <div
        className="word"
        style={{
          position: 'relative',
          color: '#f2f2f2',
          paddingLeft: '25px',
          fontSize: '14vw',
          letterSpacing: '3vw',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          fontWeight: '500',
          marginTop: '-400px',
        }}
      >
        {animateText('A B O U T', aboutInView)}
      </div>

      <div
        style={{
          position: 'absolute',
          width: '70%',
          right: '0',
          left: '0',
          margin: 'auto',
        }}
      >
        <div
          className="titleHolder"
          style={{
            fontSize: '36px',
            fontWeight: '600',
            color: '#000',
            width: '35%',
            marginBottom: '25px',
            lineHeight: '40px',
          }}
        >
          Transforming Spaces, Inspiring Lives{' '}
        </div>

        <div
          ref={paragraphsRef}
          className="aboutSection"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <div className="about">
            As the creative mind behind Nb Interiors, I approach each project
            with a deep sense of passion and purpose. Since teh very begining, I
            have immersed myself in the art of interior design, believing that
            every space is a unique canvas waiting to be transformed. My mission
            is to create environments that reflect the individuality of my
            clients while balancing aesthetics and functionality. My portfolio
            showcases a diverse range of projects, each telling its own story,
            reflecting my dedication to quality and the joy of making design
            dreams come true.
          </div>
          <div className="about" style={{ marginLeft: '50px' }}>
            I believe that every space tells a story and has the potential to
            inspire. My goal is to transform ordinary environments into
            extraordinary experiences through thoughtful design. Each project is
            an opportunity to understand and fulfill the unique needs of my
            clients, fostering collaboration and creativity. As I continue to
            expand my portfolio, I remain committed to making a meaningful
            impact in the world of interior design, one project at a time.
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

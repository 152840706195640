import React from 'react';
import { Button, Container, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Footer = ({ footerData }) => {
  const isMobile = useMediaQuery('(max-width:680px)');
  const navigate = useNavigate();

  const {
    companyName,
    companyTagline,
    navLinks,
    developerText,
    developerLogo,
    copyrightText,
  } = footerData;

  return (
    <Container
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(31, 31, 31, 0.99)',
        maxWidth: 'unset',
      }}
    >
      <footer
        style={{
          display: 'flex',
          margin: 'auto',
          color: '#fff',
          justifyContent: 'space-around',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          className="footer"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: isMobile ? '90vw' : '70vw',
            alignItems: 'center',
            minHeight: '20vh',
            margin: '30px 20px',
          }}
        >
          <div className="footerSection" style={{ width: '55%' }}>
            <h2>{companyName}</h2>
            <p>{companyTagline}</p>
          </div>

          <div
            className="footerSection"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '45%',
              color: '#fff',
              flexWrap: 'wrap',
            }}
          >
            {navLinks.map((link, index) => (
              <Button
                key={index}
                sx={{ color: '#fff' }}
                onClick={() => {
                  const isHashLink = link.path.includes('#'); // Check if it's a hash link
                  const [path, hash] = link.path.split('#'); // Split the path and hash if it exists

                  if (isHashLink) {
                    // If the link includes a hash, navigate to the new page
                    navigate(path); // Navigate to the page
                    setTimeout(() => {
                      const targetSection = document.querySelector(`#${hash}`);
                      if (targetSection) {
                        targetSection.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the section
                      }
                    }, 100); // Delay for a brief moment to allow the page to load before scrolling
                  } else {
                    navigate(link.path); // Navigate to the other page directly
                  }
                }}
              >
                {link.label}
              </Button>
            ))}
          </div>
        </div>
        <div
          className="footerLogo"
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '25px',
            alignItems: 'center',
            width: isMobile ? '90vw' : '70vw',
          }}
        >
          <span style={{ width: '100px', lineHeight: '20px' }}>
            {developerText}
          </span>

          <img
            style={{
              width: 'auto',
              height: '45px',
            }}
            src={developerLogo}
            alt="developer logo"
          />
        </div>
        <div
          style={{
            color: '#fff',
            backgroundColor: 'rgb(34 34 34)',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          © {new Date().getFullYear()} {copyrightText}. All rights reserved.
        </div>
      </footer>
    </Container>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import { Container, useMediaQuery } from '@mui/material';
import images from '../data/images';
import About from '../components/About';
import Categories from '../components/Categories';
import Contact from '../components/Contact';
import CustomCarousel from '../components/test';

const Home = () => {
  const isMobile = useMediaQuery('(max-width:900px)')
    ? 'vertical'
    : 'horizontal';
  const [carouselHeight, setCarouselHeight] = useState('100vh'); // Initial height

  // // Handle scroll event to adjust the height
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop =
  //       window.pageYOffset || document.documentElement.scrollTop;
  //     const newHeight = Math.max(75, 100 - scrollTop / 10); // Adjust values as needed (min 75vh)
  //     setCarouselHeight(`${newHeight}vh`);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <div>
      <Container
        style={{ padding: '0', margin: '0' }}
        className="section1"
        sx={{
          height: carouselHeight,
          width: '100%',
          transition: 'height 0.3s ease', // Smooth height transition
        }}
      >
        <CustomCarousel images={images} />
      </Container>

      <div
        className="brightSection "
        id="aboutSection"
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <About />
      </div>

      <Container
        className="brightSectionHolder"
        sx={{
          height: '100vh',
        }}
      >
        <Categories />
      </Container>

      <Container
        id="profileSection"
        style={{
          height: isMobile ? 'unset' : '60vh',
        }}
      >
        <div
          className="fixedImage"
          style={{
            margin: isMobile ? '' : '20vh',
          }}
        ></div>
      </Container>

      <div className={`profileSection ${isMobile}`}></div>
      <Container
        id="contactSection"
        className="brightSection brightSectionContact contactSection"
        sx={{
          width: '100%',
          height: '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Contact />
      </Container>
    </div>
  );
};

export default Home;
